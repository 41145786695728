/* --------------------------------------
		CUSTOM FUNCTION WRITE HERE
-------------------------------------- */
var ready1, set_positions;

set_positions = function () {
    // loop through and give each task a data-pos
    // attribute that holds its position in the DOM
    $('.degree-item').each(function (i) {
        $(this).attr("data-pos", i + 1);
    });
}

ready1 = function () {

    // call set_positions function
    set_positions();

    $('.degree-sortable').sortable({
        handle: '.handle'
    });

    // after the order changes
    $('.degree-sortable').sortable().bind('sortupdate', function (e, ui) {
        // array to store new order
        updated_order = []
        // set the updated positions
        set_positions();

        // populate the updated_order array with the new task positions
        $('.degree-item').each(function (i) {
            updated_order.push({id: $(this).data("id"), position: i + 1});
        });

        // send the updated order via ajax
        $.ajax({
            type: "PUT",
            url: '/admin/doctor_degrees/sort',
            data: {order: updated_order}
        });
    });
}
// jQuery(document).on('ready turbolinks:load', function() {});
// jQuery(document).on('ready', function () {
jQuery(document).on('ready turbolinks:load', function () {
// jQuery(document).on('turbolinks:load', function () {
    "use strict";

   



    /* -------------------------------------
                PRELOADER
        -------------------------------------- */
    jQuery(window).on('ready turbolinks:load', function () {
        jQuery(".preloader-outer").delay(2000).fadeOut();
        jQuery(".pins").delay(2000).fadeOut("slow");
    });

    jQuery("#add_degrees_token").tokenInput("/degrees.json", {
        theme: "facebook",
        preventDuplicates: true,
        prePopulate: $('#add_degrees_token').data('load')
    });

    // Initialize Dynamic Selectable JS
    (function () {
        ready1();
        window.initApp = function () {
            return jQuery('select[data-dynamic-selectable-url][data-dynamic-selectable-target]').dynamicSelectable();
        };

        document.addEventListener('page:load', initApp);

        jQuery(initApp);

    }).call(this);
});

