// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

require("plugins/jquery.tokeninput")
require("plugins/jquery.sortable")
require("custom/home")
require("theme_js/bootstrap")
// require("theme_js/bootstrap-timepicker")
// require("theme_js/customScrollbar")
// require("theme_js/jquery.countdown")
// require("theme_js/owl.carousel")
// require("theme_js/isotope.pkgd")
// // require("theme_js/packery-mode")
// require("theme_js/svg-injector")
// require("theme_js/moment")
// require("theme_js/fullcalendar")
// require("theme_js/jquery-ui")
// require("theme_js/collapse")
// require("theme_js/parallax")
// require("theme_js/readmore")
// require("theme_js/countTo")
// require("theme_js/loader")
// require("theme_js/appear")
// require("theme_js/gmap3")
require("plugins/dynamic_selectable")
require("theme_js/main")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


